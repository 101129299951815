import { Loader } from '@refera/ui-web'

import React, { useCallback, useEffect, useState } from 'react'
import HeaderTitle from '_/components/header-title'
import { Grid, Button } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useToast } from '_/hooks/use-toast'
import { navigate, useParams } from '@reach/router'
import useStyles from './styles'
import { BasicInput, Select } from '_/components/inputs'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_/modules/authentication/selectors'
import ModalDialog, { WARNING_MODAL } from '_/components/modal/modal-dialog'
import { getProfilesSelector } from '_/modules/profile/selectors'
import {
  createPermissionGroup,
  editPermissionGroup,
  getProfileList,
} from '_/modules/profile/actions'
import { createSlug } from '_/utils/permissions'
import { ROUTES } from '_/utils/constants'
import { getGroupSelector } from '_/modules/group/selectors'
import { getGroupById } from '_/modules/group/actions'

export function AddPermissions() {
  const user = useSelector(userSelector)
  const profiles = useSelector(getProfilesSelector)
  const group = useSelector(getGroupSelector)
  const dispatch = useDispatch()
  const styles = useStyles()

  const [modalDialog, setModalDialog] = useState({
    isOpen: false,
    textOkButton: 'Ok, entendi',
    subTitle: `Salve antes as edições deste registro.`,
    type: WARNING_MODAL,
  })

  const { groupId } = useParams()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { showToast } = useToast()

  // Form constants
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      desc: '',
      profile: null,
    },
  })

  const { handleSubmit, reset, setValue, watch } = methods

  const goBack = () => {
    navigate(`${ROUTES.GROUP_PERMISSIONS}`)
  }

  const handleSubmitForm = useCallback(data => {
    const newData = {
      ...data,
      name: createSlug(data.desc),
    }

    if (groupId) {
      return dispatch(editPermissionGroup(groupId, newData))
        .then(() => {
          getGroupProfile()
          showToast({ type: 'success' })
        })
        .catch(error => {
          if (error?.non_field_errors?.[0]) {
            return showToast({
              type: 'error',
              message: 'Já há um registro com este mesmo nome.',
            })
          }
          return showToast({
            type: 'error',
            message: 'Ocorreu um erro ao editar o grupo de permissões.',
          })
        })
    }

    return dispatch(createPermissionGroup(newData))
      .then(response => {
        showToast({ type: 'success' })

        navigate(`${ROUTES.MANAGER_PERMISSIONS}/${response?.id}`)
        handleReset()
      })
      .catch(error => {
        if (error?.error_message && error?.error_code === 'invalid') {
          return showToast({
            type: 'error',
            message: error?.error_message,
          })
        }
        return showToast({
          type: 'error',
          message: 'Ocorreu um erro ao salvar o grupo de permissões.',
        })
      })
  }, [])

  const getProfiles = useCallback(() => {
    if (!profiles?.results || profiles?.results?.length === 0) {
      setIsSubmitting(true)

      return dispatch(getProfileList())
        .then(() => {
          return setIsSubmitting(false)
        })
        .catch(() => {
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os profiles.' })
          return setIsSubmitting(false)
        })
    }
    return null
  }, [dispatch, user, profiles])

  const getGroupProfile = useCallback(() => {
    if (groupId) {
      setIsSubmitting(true)

      return dispatch(getGroupById(groupId))
        .then(data => {
          setValue('desc', data?.desc)
          setValue('profile', data?.profile)
          return setIsSubmitting(false)
        })
        .catch(() => {
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar o grupo de permissão.' })
          return setIsSubmitting(false)
        })
    }
    return null
  }, [dispatch, user])

  function handleReset() {
    if (groupId) {
      getGroupProfile()
    }

    return reset()
  }

  const handlePermissionAction = useCallback(
    name => {
      const descWatch = watch('desc')
      const profileWatch = watch('profile')
      if (groupId) {
        if (descWatch !== group?.desc || profileWatch !== group?.profile) {
          return setModalDialog(prevState => ({ ...prevState, isOpen: true }))
        }

        return navigate(`${ROUTES.GROUP}/${name}/${groupId}`)
      }

      if (descWatch || profileWatch) {
        return setModalDialog(prevState => ({ ...prevState, isOpen: true }))
      }

      return null
    },
    [group, groupId, watch]
  )

  // On page first load
  useEffect(() => {
    getProfiles()
  }, [])

  useEffect(() => {
    getGroupProfile()
  }, [groupId, profiles])

  return (
    <Grid className={styles.app}>
      <HeaderTitle title="Grupo de permissão" backButtonAction={goBack}>
        <Grid style={{ display: 'flex', gap: '8px' }}>
          <Button
            className={[styles.buttonBlue, styles.button]}
            onClick={() => handlePermissionAction('acoes')}
          >
            Permissões de ações
          </Button>
          <Button
            className={[styles.buttonBlue, styles.button]}
            onClick={() => handlePermissionAction('dados')}
          >
            Permissões de dados
          </Button>
          <Button className={[styles.buttonRed, styles.button]} onClick={() => handleReset()}>
            Cancelar
          </Button>
          <Button
            className={[styles.buttonFullBlue, styles.button]}
            form="add-permissions"
            onClick={handleSubmit(handleSubmitForm)}
          >
            Salvar
          </Button>
        </Grid>
      </HeaderTitle>
      <FormProvider {...methods}>
        <form
          id="add-permissions"
          onSubmit={handleSubmit(handleSubmitForm)}
          className={styles.container}
        >
          <BasicInput
            label="Nome"
            name="desc"
            required
            containerClasses={styles.input}
            labelClasses={styles.label}
            rules={{
              required: { value: true, message: 'Esse campo é obrigatório' },
              maxLength: { value: 255, message: `Esse campo aceita no máximo 255 caracteres` },
            }}
          />

          {profiles?.results?.length > 0 && (
            <Select
              label="Perfil"
              name="profile"
              key="profile"
              containerClasses={styles.input}
              options={profiles?.results}
              rules={{ required: { value: true, message: 'Esse campo é obrigatório' } }}
              required
              getValue={item => item.id}
              getLabel={item => item.desc}
              getKey={item => item.id}
              menuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              }}
              labelClasses={styles.label}
              style={{ gridArea: 'businessFront' }}
            />
          )}
        </form>
      </FormProvider>

      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      <Loader hasBackdrop open={isSubmitting} label="Aguarde..." />
    </Grid>
  )
}
