import { Cookies } from 'react-cookie'
import humps from 'humps'

import { name } from './constants'

import { navigate } from '@reach/router'

export const getToken = (config = false) => {
  const cookies = new Cookies()
  const token = cookies.get(name, { path: '/' })

  if (config) {
    return { headers: { Authorization: `Token ${token}`, ...config } }
  }

  return { headers: { Authorization: `Token ${token}` } }
}

export const removeToken = () => {
  const cookies = new Cookies()

  if (process.env.NODE_ENV === 'development') {
    cookies.remove('authTokenrefera', { path: '/' })
  } else {
    cookies.remove('authToken', { path: '/' })
  }
  navigate('/login')
}

export const camelize = data => {
  const camelized = []

  if (data) {
    if (data.length > 0) {
      data.map(obj => camelized.push(humps.camelizeKeys(obj)))
      return camelized
    }

    if (typeof data === 'object') {
      return humps.camelizeKeys(data)
    }

    return humps.camelize(data)
  }

  return false
}
