import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ breakpoints, spacing, palette }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 55px',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '30px 50px',
  },
  title: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '112.5%',
    color: '#141414',
  },
  fieldsContainer: {
    display: 'flex',
    gap: '30px',
    width: '100%',
    justifyContent: 'space-between',
    '& > *': {
      flex: '1 1 auto',
      minWidth: '0',
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  rulesField: {
    fontSize: Theme.Typography.FontSize.Tiny,
    fontFamily: [Theme.Typography.FontFamily.Roboto, 'sans-serif'],
    fontWeight: Theme.Typography.FontWeight.Small,
  },
  radioLabel: {
    lineHeight: 0,
    color: palette.gray[64],
    fontSize: spacing(14),
    marginBottom: spacing(4),
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '72px',
    width: '100%',
    padding: '0px 100px',
    borderTop: '1px solid #E3E3E3',
    borderBottom: '1px solid #E3E3E3',
  },
  grid: {
    width: '100%',
    maxWidth: '100%',
  },
  error: {
    color: palette.custom.red,
    fontSize: spacing(12),
  },
  select: {
    marginTop: '0 !important',
  },
  passwordField: {
    marginTop: '25px',
  },
  required: {
    color: palette.custom.red,
    marginLeft: spacing(4),
  },
  errorMessage: {
    color: `${palette.error.main} !important`,
    margin: 0,
    fontSize: spacing(10.5),
    fontFamily: 'Roboto',
    height: spacing(10),
    display: 'flex !important',
  },
  checkbox: {
    marginRight: spacing(5),
  },
}))
