import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from './styles'
import Button from '_/components/button'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { getPermissionsGroupSelector } from '_/modules/profile/selectors'
import { Select } from '_/components/inputs'
import { getProfilePermissionGroup } from '_/modules/profile/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export function AddGroupPermissionModal({ modalOpen, handleModal, handleSubmit, isLoading }) {
  const styles = useStyles()
  const [selectedState, setSelectedState] = useState()
  const dispatch = useDispatch()
  const groupsOptions = useSelector(getPermissionsGroupSelector)

  const { errors } = useFormContext()

  useEffect(() => {
    if (!groupsOptions?.results || groupsOptions?.results.length === 0) {
      dispatch(
        getProfilePermissionGroup({
          profile: 'refera',
          pageSize: 1000,
        })
      )
    }
  }, [groupsOptions.length, dispatch])

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xl"
      onClose={handleModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      root={styles.modal}
      disabledSubmit={isLoading}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} className={styles.titleBlue} id="draggable-dialog-title">
        <Typography className={styles.title}>
          Selecione um grupo de permissão
          <IconButton
            aria-label="close"
            onClick={handleModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent container className={styles.wrapper}>
        <Select
          name="addGroupPermission"
          onChange={e => setSelectedState(e)}
          required={false}
          error={!!errors?.addGroupPermission}
          options={groupsOptions?.results || []}
          getValue={item => item.id}
          getLabel={item => item.desc}
          getKey={item => item.id}
          menuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
          style={{ gridArea: 'businessFront' }}
          containerClasses={styles.select}
        />
      </DialogContent>
      <DialogActions className={styles.dialogAction}>
        <Button
          className={styles.button}
          onClick={handleModal}
          disabled={isLoading}
          color="red"
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          className={styles.button}
          onClick={() => handleSubmit({ ...selectedState })}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
