/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, Accordion, AccordionSummary } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { ExpandMore } from '@material-ui/icons'

import Button from '_components/button'
import useModal from '_hooks/use-toggle'
import { getCitiesOptions } from '_modules/utils/selectors'
import { getCities } from '_modules/utils/actions'
import AccordionInfo from '_/components/accordion/accordion-info'

import AddCityModal from './add-city-modal'
import useStyles from './styles'

export const AddCity = ({ user, isProvider, isEditing, setValue }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const cityOptions = useSelector(getCitiesOptions)
  const [modalOpen, handleModal] = useModal()
  const [regions, setRegions] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = formValues => {
    const isDuplicated = regions.find(x => x.city === formValues.city)

    if (formValues.city === '' || formValues.state === '') {
      return
    }

    if (!isDuplicated) {
      setRegions([...regions, formValues])
    }

    setSubmitting(true)
  }

  const [regionStates, setRegionStates] = useState()
  const [regionStatesNames, setRegionStatesNames] = useState()

  const handleAddRegion = () => {
    const newRegionStates = []
    const newRegionStatesNames = []
    cityOptions.map(city => {
      newRegionStates.push({ id: city.state.id, name: city.state.name })
      newRegionStatesNames.push(city.state.name)
    })
    setRegionStates([...new Set(newRegionStates)])
    setRegionStatesNames([...new Set(newRegionStatesNames)])
    handleModal()
  }

  useEffect(() => {
    if (user?.cities?.length > 0 && regions.length < 1) {
      handleInitialState()
    }
  }, [])

  const handleInitialState = () => {
    const initialRegionCities = []
    const cities = []

    user?.cities?.map(city => {
      initialRegionCities.push(city)
    })

    cityOptions
      .filter(city => {
        return initialRegionCities.includes(city.value)
      })
      .map(city => {
        const data = {
          state: city.state.name,
          city: city.label,
        }
        cities.push(data)
      })
    setRegions(cities)
  }

  useEffect(() => {
    const cityNames = []
    const finalCitySlugs = []

    if (regions.length === 0) {
      setValue('cities', '')
    }

    regions.map(x => cityNames.push(x.city))

    cityOptions
      .filter(city => {
        return cityNames.includes(city.label)
      })
      .map(city => finalCitySlugs.push(city.value))

    setValue('cities', finalCitySlugs)
    setSubmitting(false)
  }, [isSubmitting])

  const handleDeleteRegion = cityObj => {
    const updatedRegions = regions.filter(currentCity => currentCity.city !== cityObj.city)
    setRegions(updatedRegions)
    if (updatedRegions.length === 0) {
      setRegions([])
    }
    setSubmitting(true)
  }

  useEffect(() => {
    if (!cityOptions.length) {
      dispatch(getCities())
    }
  }, [cityOptions.length, dispatch])

  return (
    <Accordion id="user-regions" defaultExpanded disableGutters className={styles.container}>
      <AccordionSummary
        expandIcon={<ExpandMore className={styles.icon} />}
        aria-controls="header-content"
        className={styles.accordionTitle}
        id="header"
      >
        <Typography className={styles.title}>Regiões que o usuário atende</Typography>
      </AccordionSummary>
      <Grid className={styles.cityList}>
        {regions.length === 0 && !isEditing && (
          <Typography className={styles.subTitle}>Nenhuma cidade adicionada</Typography>
        )}
        {regions
          .sort((a, b) => a.state.localeCompare(b.state))
          .map((region, index) => (
            <AccordionInfo
              key={region}
              isFirst={index === 0}
              type="region"
              values={region}
              isProvider={isProvider}
              isEditing={isEditing}
              handleDeleteValue={handleDeleteRegion}
            />
          ))}
      </Grid>
      <Grid>
        {isEditing && (
          <Button
            className={styles.button}
            variant="outlined"
            color="primary"
            onClick={handleAddRegion}
          >
            + Adicionar mais cidades
          </Button>
        )}
      </Grid>
      {modalOpen && (
        <AddCityModal
          modalOpen={modalOpen}
          regionsList={regions}
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          cityOptions={cityOptions}
          stateOptions={regionStates}
          stateNames={regionStatesNames.sort()}
          isProvider={isProvider}
        />
      )}
    </Accordion>
  )
}
