/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import axios from 'axios'
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Input,
  FormHelperText,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { includeDDI } from '_/views/new-service/utils/includeDDI'

import { getClassification } from '_/modules/classification/actions'
import { classificationsSelector } from '_modules/classification/selectors'

import { API_URL } from '_config/environment'
import Loading from '_/components/loading'
import { getToken, camelize } from '_utils/token'
import { addAgencyStaffUser, getAgency, patchAgencyStaffUser } from '_/modules/agency/actions'
import IconButton from '_/components/svg/icon-button'
import { agencySelector } from '_modules/agency/selectors'

import { AddCity } from './add-city'
import { BasicInput, MaskBasicInput } from '_/components/inputs'
import useStyles from './styles'
import NavbarButtons from './navbar-buttons'

import { validatePasswordStrength } from '_/utils/helpers'
import { AddGroupPermissions } from './add-group-permission'
import { getProfilePermissionGroup } from '_/modules/profile/actions'
import { getPermissionsGroupSelector } from '_/modules/profile/selectors'
import { EMAIL_REGEX, PHONE_REGEX } from '_/utils/constants'
import PasswordInput from '_/components/inputs/PasswordInput'
import { Checkbox } from '@mui/material'
import { userSelector } from '_/modules/authentication/selectors'

const AgencyUser = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const userLogged = useSelector(userSelector)
  const { agencyId, userId } = useParams()
  const { showToast } = useToast()

  const agency = useSelector(agencySelector)
  const classifications = useSelector(classificationsSelector)

  const groupsPermission = useSelector(getPermissionsGroupSelector)
  const [user, setUser] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [requestLoading, setRequestLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(!userId)
  const [permissionsGroup, setPermissionsGroup] = useState([])

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
    },
  })

  const { handleSubmit, control, setValue, errors, watch } = methods
  const isAdminObserver = watch('isAdmin')

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const handleNavigate = () => {
    navigate(`/imobiliaria/${agencyId}`)
  }

  const handleSave = useCallback(
    async dataObject => {
      // eslint-disable-next-line no-unused-vars
      const { addGroupPermission, password, ...data } = dataObject

      setRequestLoading(true)
      if (user?.email === data.email) {
        delete data.email
      }
      let passwordIsValid = true
      if (data?.password?.length > 0) {
        passwordIsValid = validatePasswordStrength(data?.password)
      }

      if (passwordIsValid) {
        // FIXME: Remove axios and use the project's structure to integrate with BE
        try {
          const payload =
            password === ''
              ? {
                  permissionsGroup: permissionsGroup.map(permission => permission.id),
                  ...data,
                }
              : {
                  password,
                  permissionsGroup: permissionsGroup.map(permission => permission.id),
                  ...data,
                }

          if (!userId) {
            await dispatch(addAgencyStaffUser(payload))
              .then(() => {
                setRequestLoading(false)
                handleNavigate()
              })
              .catch(err => {
                if (err.response) {
                  if (err.error_message) {
                    showToast({
                      message: err.error_message,
                      type: 'error',
                    })
                  } else if (err.response) {
                    showToast({
                      message: err.response.data,
                      type: 'error',
                    })
                  }
                }
                setRequestLoading(false)
              })
          } else {
            await dispatch(patchAgencyStaffUser(userId, payload))
              .then(() => {
                setRequestLoading(false)
                handleNavigate()
              })
              .catch(err => {
                console.info({ err }, err.error_message)
                if (err.error_message) {
                  showToast({
                    message: err.error_message,
                    type: 'error',
                  })
                } else if (err.response) {
                  showToast({
                    message: err.response.data,
                    type: 'error',
                  })
                }
                setRequestLoading(false)
              })
          }
        } catch (err) {
          if (err.response) {
            showToast({
              message: err.response.data,
              type: 'error',
            })
            setRequestLoading(false)
          }
        }
      } else {
        showToast({ message: 'A senha não atende os requisitos mínimos.', type: 'error' })
      }
      return setRequestLoading(false)
    },
    [permissionsGroup, showToast, dispatch]
  )

  useEffect(() => {
    const headers = getToken()
    const urlStart = `${API_URL}/agency/${agencyId}/staff-users/`
    const url = userId ? `${urlStart}${userId}/` : urlStart

    // FIXME: Remove axios and use the project's structure to integrate with BE
    axios
      .get(url, headers)
      .then(res => setUser(camelize(res.data)))
      .then(() => setPageLoading(false))
  }, [agencyId, userId]) // FETCHES USER

  useEffect(() => {
    if (agencyId) {
      dispatch(getAgency(agencyId))
    }
  }, [agencyId]) // FETCHES AGENCY

  useEffect(() => {
    if (user?.permissionsGroup) {
      setPermissionsGroup(user.permissionsGroup)
    }
    if (user?.getRoles) {
      setValue('role', user.getRoles)
    }
  }, [user])

  useEffect(() => {
    dispatch(getClassification({ pageSize: 1000, active: 'True', agency: agencyId }))
  }, [])

  const getPermissionsGroup = useCallback(() => {
    if (!groupsPermission?.results || groupsPermission?.results?.length === 0) {
      setPageLoading(true)

      return dispatch(
        getProfilePermissionGroup({
          profile: userLogged?.activeProfile,
          pageSize: 1000,
        })
      )
        .then(() => {
          return setPageLoading(false)
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Ocorreu um erro ao buscar os registros dos grupos de permissões.',
          })
          return setPageLoading(false)
        })
    }

    return null
  }, [dispatch])

  // On page first load
  useEffect(() => {
    getPermissionsGroup()
  }, [])

  if ((pageLoading && userId) || (!user?.id && userId)) {
    return <Loading />
  }

  return (
    <>
      <Grid className={styles.main}>
        <Grid className={styles.titleWrap}>
          <IconButton aria-label="Voltar página" onClick={handleNavigate}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={styles.title}>
            Cadastro imobiliária / {agency?.toJS().name}
          </Typography>
        </Grid>
        <Grid className={styles.navbar}>
          <NavbarButtons
            isEditing={isEditing}
            isLoading={requestLoading}
            setIsEditing={setIsEditing}
            handleCancel={handleNavigate}
          />
        </Grid>
        <FormProvider {...methods}>
          <form id="user-form" onSubmit={handleSubmit(handleSave)} className={styles.container}>
            <Grid className={styles.fieldsContainer}>
              <BasicInput
                label="Nome completo"
                name="name"
                labelClasses={styles.radioLabel}
                required
                disabled={!isEditing}
                defaultValue={user?.name || ''}
              />
              <BasicInput
                label="E-mail"
                name="email"
                required
                labelClasses={styles.radioLabel}
                disabled={!isEditing}
                defaultValue={user?.email || ''}
                rules={{
                  pattern: { value: EMAIL_REGEX, message: 'E-mail inválido' },
                  required: { value: true, message: 'Esse campo é obrigatório' },
                }}
              />
              <MaskBasicInput
                label="Telefone"
                name="phone"
                mask="+55 (99) 9 9999-9999"
                required
                labelClasses={styles.radioLabel}
                disabled={!isEditing}
                rules={{
                  required: { value: true, message: 'Esse campo é obrigatório' },
                  pattern: { value: PHONE_REGEX, message: 'Telefone inválido' },
                }}
                defaultValue={user?.phone ? includeDDI(user?.phone) : ''}
              />
            </Grid>
            <Grid className={styles.fieldsContainer}>
              <PasswordInput
                label="Senha"
                name="password"
                rules={{
                  required: !userId && 'Senha é obrigatória',
                  minLength: { value: 8, message: 'A senha precisa ter no mínimo 8 caracteres' },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                    message:
                      'A senha precisa conter letras maiúsculas, minúsculas, números e caracteres especiais',
                  },
                }}
                disabled={!isEditing}
                containerClasses={styles.passwordField}
                labelClasses={styles.radioLabel}
              />
              <Controller
                name="role"
                control={control}
                required
                defaultValue={user?.getRoles}
                as={
                  <FormControl style={{ width: '100%', marginTop: '10px' }}>
                    <FormLabel id="access-level" className={styles.radioLabel}>
                      <Typography className={styles.radioLabel} variant="body1">
                        Qual o nível de acesso? <span className={styles.required}>*</span>
                      </Typography>
                    </FormLabel>
                    <RadioGroup row defaultValue={user?.getRoles} aria-labelledby="access-level">
                      <FormControlLabel
                        value="cs_approver"
                        control={<Radio required />}
                        disabled={!isEditing}
                        label="CS Aprovador"
                        required
                      />
                      <FormControlLabel
                        value="cs_manager"
                        control={<Radio required />}
                        disabled={!isEditing}
                        label="CS Gestor"
                        required
                      />
                    </RadioGroup>
                    <FormHelperText className={styles.errorMessage} error={!!errors?.role}>
                      {errors?.role?.message}
                    </FormHelperText>
                  </FormControl>
                }
              />
              <Controller
                name="classification"
                control={control}
                defaultValue={user?.classification || []}
                as={
                  <FormControl style={{ width: '100%' }}>
                    <FormLabel shrink id="classification" className={styles.radioLabel}>
                      <Typography variant="body1">Classificação</Typography>
                    </FormLabel>
                    <Select
                      multiple
                      disabled={!isEditing}
                      labelId="classification"
                      id="multiple-classifications"
                      aria-labelledby="classification"
                      onChange={e => setValue('classification', e.target.value)}
                      defaultValue={user?.classification || []}
                      input={<Input label="Classificação" />}
                      MenuProps={menuProps}
                      className={styles.select}
                    >
                      {classifications.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.classificationName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              />
            </Grid>
            <Grid className={styles.fieldsContainer}>
              <Controller
                name="isAdmin"
                control={control}
                defaultValue={user?.isAdmin}
                disabled={!isEditing}
                render={({ value, onChange }) => {
                  return (
                    <FormControlLabel
                      label="Admin"
                      control={
                        <Checkbox
                          className={styles.checkbox}
                          checked={value}
                          disabled={!isEditing}
                          defaultChecked={user?.isAdmin}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  )
                }}
              />

              <Controller
                name="isActive"
                control={control}
                defaultValue={user?.isActive}
                disabled={!isEditing}
                render={({ value, onChange }) => {
                  return (
                    <FormControlLabel
                      label="Ativo"
                      control={
                        <Checkbox
                          className={styles.checkbox}
                          checked={value}
                          disabled={!isEditing}
                          defaultChecked={user?.isActive}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  )
                }}
              />
              <div />
            </Grid>
            {!isAdminObserver && (
              <AddGroupPermissions
                permissionsGroup={permissionsGroup}
                setPermissionsGroup={setPermissionsGroup}
                isEditing={isEditing}
              />
            )}
            <AddCity
              isProvider
              user={user}
              staffUsers={user?.cities}
              isEditing={isEditing}
              setValue={setValue}
            />
          </form>
        </FormProvider>
      </Grid>
    </>
  )
}

export default AgencyUser
