import React from 'react'

import Button from '_/components/button'

import useStyles from './styles'

const NavbarButtons = ({ isEditing, setIsEditing, handleCancel, isLoading }) => {
  const styles = useStyles()

  return (
    <>
      {!isEditing ? (
        <Button
          onClick={() => setIsEditing(prevState => !prevState)}
          className={styles.buttons}
          variant="outlined"
        >
          Editar
        </Button>
      ) : (
        <>
          <Button className={styles.buttonRed} variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            className={styles.buttonSend}
            type="submit"
            color="primary"
            variant="contained"
            form="user-form"
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </>
      )}
    </>
  )
}

export default NavbarButtons
