import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    width: '400px',
    marginRight: '110px',
    justifyContent: 'center',
  },
  buttons: {
    padding: '7px 15px',
    color: '#1671ED',
    marginRight: '10px',
    borderColor: '#1671ED',
    borderRadius: '20px',
    textTransform: 'none',
  },
  buttonRed: {
    padding: '7px 15px',
    marginRight: '10px',
    borderRadius: '20px',
    textTransform: 'none',
    color: 'red',
    borderColor: 'red',
  },
  buttonSend: {
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}))
