import { Cookies } from 'react-cookie'
import { defineAction as reduxDefine } from 'redux-define'

const REQUEST = ['PENDING', 'FULFILLED', 'REJECTED', 'COUNT']

export const defineAction = type => reduxDefine(type, REQUEST)
const cookies = new Cookies()
export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) => {
    if (action.type === 'LOGOUT') {
      if (process.env.NODE_ENV === 'development') {
        cookies.remove('authTokenrefera', { path: '/' })
      } else {
        cookies.remove('authToken', { path: '/' })
      }
      return initialState
    }
    const reduceFn = handlers[action.type]
    return reduceFn ? reduceFn(state, action) : state
  }

export const getActionName = name =>
  name.toString().replace(/_PENDING$|_REJECTED$|_FULFILLED$|_COUNT$/, '')
